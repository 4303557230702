<template>
  <div>
    <b-row class="mb-5" style="color: #0a2799" v-show="!$store.state.cancelFromLinkMessage">
      <b-col>
        <b-card-title>Consultar / Cancelar Citas</b-card-title>
      </b-col>
    </b-row>
    <!-- <b-row class="mb-5">
      <b-col>
        <label for=""
          >Seleccione el afiliado del grupo familiar que desea consultar:</label
        >
        <b-form-select
          v-model="afilSelected"
          :options="itemsGrupoFamiliar"
          @change="listarCitas()"
        >
        </b-form-select>
      </b-col> 
    </b-row> -->
    <b-row align-h="center" class="mt-5" v-show="loading">
      <b-container>
        <b-row align-h="center">
          <div class="d-flex justify-content-center">
            <b-icon
              icon="arrow-repeat"
              animation="spin-reverse"
              font-scale="4"
            ></b-icon>
          </div>
        </b-row>
        <b-row align-h="center">
          <div class="d-flex justify-content-center">
            <b-card-text>Consultando listado de citas</b-card-text>
          </div>
        </b-row>
      </b-container>
    </b-row>
    <b-row align-h="center" class="mt-5" v-show="cancelando">
      <b-container>
        <b-row align-h="center">
          <div class="d-flex justify-content-center">
            <b-icon
              icon="arrow-repeat"
              animation="spin-reverse"
              font-scale="4"
            ></b-icon>
          </div>
        </b-row>
        <b-row align-h="center">
          <div class="d-flex justify-content-center">
            <b-card-text>Realizando cancelación...</b-card-text>
          </div>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="mt-5" v-show="items.length > 0 && !loading && !cancelando">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <h6 v-show="!$store.state.cancelFromLinkMessage">Listado de Citas para el afiliado:</h6>
        <b-table
          v-if="!$store.state.cancelFromLinkMessage"
          :fields="fields"
          :items="items"
          small
          head-variant="dark"
          bordered
          responsive
          id="tbl-lista-citas"
        >
          <template #cell(cancelar)="data">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="cancelarCita(data.item)"
              >Cancelar Cita</b-button
            >
          </template>
        </b-table>
        <b-table
          v-else
          :fields="fieldsCancel"
          :items="items"
          small
          head-variant="dark"
          bordered
          responsive
          id="tbl-lista-citas"
        >
          <template #cell(cancelar)="data">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="cancelarCita(data.item)"
              >Cancelar Cita</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      v-if="items.length <= 0 && !loading && this.afilSelected !== null"
    >
      <b-col>
        <b-card-text class="text-center" style="font-weight: bolder"
          >Sin citas</b-card-text
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import moment from "moment";

  export default {
    props: ["docSelected", "fechaCitaCancelar"],
    data: () => {
      return {
        loading: false,
        cancelando: false,
        fields: [
          { key: "especialidad", label: "Especialidad" },
          { key: "medico", label: "Médico" },
          { key: "fecha", label: "Fecha" },
          { key: "hora", label: "Hora" },
          { key: "unidad", label: "Unidad" },
          { key: "direccion", label: "Dirección" },
          { key: "cancelar", label: "" },
        ],
        fieldsCancel: [
          { key: "cancelar", label: "" },
          { key: "especialidad", label: "Especialidad" },
          { key: "fecha", label: "Fecha" },
          { key: "hora", label: "Hora" },
        ],
        items: [],
        afilSelected: null,
        itemsGrupoFamiliar: [],
      };
    },
    computed: {
      ...mapState(["userLogged", "cita", "grupoFamiliar"]),
    },
    created() {
      this.loadInputSelect();
    },
    methods: {
      ...mapActions(["getCitasVigentes", "cancelaCita", "logout"]),
      listarCitas() {
        this.loading = true;
        this.sinCitas = false;
        this.getCitasVigentes(this.afilSelected)
          .then((rta) => {
            let isValidLink = rta.find(e => {
              if(e.origen == 'DX'){
                return e.fecha.substr(0,10) == this.fechaCitaCancelar.substr(0,10)
              }
              return e.fecha == this.fechaCitaCancelar
            })
            if(this.$store.state.cancelFromLinkMessage && !isValidLink){
              this.$toastr.e('Este link de cancelación ya no es válido.');
              this.logout().then((rta) => {
                this.$router.replace("/login");
              });
            }
            
            this.items = rta.map((el) => {   
              if(el.nombreAfiliado){
                this.$store.commit('setNombreAfiliadoAdx',el.nombreAfiliado);           
              }
              return {                
                id: el.id,
                codEspecialidad: el.origen == 'DX' ? el.CodEspecialidad : null,
                especialidad: el.NombreEspecialidad,
                medico: el.nombremedico,
                fecha: moment(el.fecha.substr(0,10)) //moment(el.fecha)
                  .locale("es")
                  .format("dddd, LL"),
                hora: el.origen == 'DX' ? el.hora : moment(el.fecha).format("hh:mm a"),
                unidad: el.nombreips,
                direccion: el.direccionips,
                fechaCompleta: moment(el.fecha.substr(0,10)).format('YYYY-MM-DD'),
                medicoAdx: el.medico,
                origen: el.origen,
                duracion: el.duracion,
                fechaOriginal: el.fecha
              };
            })
            this.items = this.items.filter(el => {
              let esDoble = this.citaDoble(el);
              if(esDoble){
                return false
              }
              return true
            })

            this.loading = false;
          })
          .catch((error) => {
            this.$toastr.e(error);
            this.loading = false;
            console.log("error", error);
            if (error.status !== null && error.status == 401) {
              this.logout().then((rta) => {
                this.$router.replace("/login");
              });
            }
          });
      },
      cancelarCita(item) {
        let idCita = item.id;        
        this.cancelando = true;
        this.cancelaCita({
          ...item,
          idCita: idCita,
          afiliado: this.afilSelected,
          especialidad: item.especialidad,
          codEspecialidad: item.codEspecialidad
        })
          .then((rta) => {
            if(rta.numeroCancelacion){
              this.showConfirmacionST(rta)
            }else{
              this.showConfirmacionDX(rta)
            }
          })
          .catch((error) => {
            this.cancelando = false;
            this.$toastr.e("No se canceló la cita.");
          });
      },
      loadInputSelect() {
        this.itemsTemp = this.grupoFamiliar.map((item) => {
          return {
            value: item.BeneficiarioId,
            text: item.Nombres,
          };
        });
        this.itemsGrupoFamiliar.push({
          value: null,
          text: "Seleccione...",
        });        
        this.itemsTemp.forEach((el) => {
          if(this.$store.state.afiliado && this.$store.state.afiliado.identificacion == el.value){
            this.itemsGrupoFamiliar.push(el);
            //Con esto se carga por defecto el afiliado logueado
            this.afilSelected = this.$store.state.afiliado.identificacion
            this.listarCitas()
          }
        });
      },
      showConfirmacionST(rta){
        this.$bvModal
              .msgBoxOk(
                `${rta.mensaje}. Número de cancelación: ${rta.numeroCancelacion}`,
                {
                  title: "Confirmación Cancelación de Cita",
                  size: "md",
                  buttonSize: "lg",
                  okVariant: "primary",
                  okTitle: "Cerrar",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                  centered: true,
                  okOnly: true,
                }
              )
              .then((value) => {
                this.cancelando = false;
                this.listarCitas();
                // this.resetStep();
                // this.logout().then((error) => {
                //   this.$router.replace("/login");
                // });
              });
            // this.$bvModal
            //   .msgBoxOk(
            //     `${rta.mensaje}. Número de cancelación: ${rta.numeroCancelacion}`
            //   )
      },
      showConfirmacionDX(){
        this.$bvModal
              .msgBoxOk(
                `Cita cancelada correctamente.`,
                {
                  title: "Confirmación Cancelación de Cita",
                  size: "md",
                  buttonSize: "lg",
                  okVariant: "primary",
                  okTitle: "Cerrar",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                  centered: true,
                  okOnly: true,
                }
              )
              .then((value) => {
                this.cancelando = false;
                this.listarCitas();
                // this.resetStep();
                // this.logout().then((error) => {
                //   this.$router.replace("/login");
                // });
              });
            // this.$bvModal
            //   .msgBoxOk(
            //     `${rta.mensaje}. Número de cancelación: ${rta.numeroCancelacion}`
            //   )
      },
      citaDoble(item){
        if(item.origen == 'DX'){
          let found = this.items.find(e => {
            return e.fecha == item.fecha && e.sede == item.sede && e.hora == moment(moment(item.hora,'hh:mm a')).add(item.duracion*(-1),'minutes').format('hh:mm a').toUpperCase()
          })
          // console.log('evaluando',item,moment(moment(item.hora,'hh:mm a')).add(item.duracion*(-1),'minutes').format('hh:mm a').toUpperCase())
          return found ? found : null
        }
      },
    },
  };
</script>
